"use strict";
//
// Useful Auth0 docs:
//
//  https://auth0.com/docs/quickstart/native/ionic-react/01-login
//  https://github.com/auth0-samples/auth0-ionic-samples
//  https://community.auth0.com/t/auth0-callback-url-with-capacitor-native-app/66293
//
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || (function () {
    var ownKeys = function(o) {
        ownKeys = Object.getOwnPropertyNames || function (o) {
            var ar = [];
            for (var k in o) if (Object.prototype.hasOwnProperty.call(o, k)) ar[ar.length] = k;
            return ar;
        };
        return ownKeys(o);
    };
    return function (mod) {
        if (mod && mod.__esModule) return mod;
        var result = {};
        if (mod != null) for (var k = ownKeys(mod), i = 0; i < k.length; i++) if (k[i] !== "default") __createBinding(result, mod, k[i]);
        __setModuleDefault(result, mod);
        return result;
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Auth0ContextProvider = Auth0ContextProvider;
const react_1 = __importStar(require("react"));
const auth0_react_1 = require("@auth0/auth0-react");
const user_interface_1 = require("user-interface");
function Auth0ContextProvider({ openUrl, children }) {
    const { isLoading, isAuthenticated, error, loginWithRedirect, logout: _logout, getAccessTokenSilently, } = (0, auth0_react_1.useAuth0)();
    (0, react_1.useEffect)(() => {
        validateAuthSettings();
    }, []);
    //
    // Logs in.
    //
    function login() {
        return __awaiter(this, void 0, void 0, function* () {
            yield loginWithRedirect({
                openUrl,
            });
        });
    }
    //
    // Logs out.
    //
    function logout() {
        return __awaiter(this, void 0, void 0, function* () {
            _logout({
                logoutParams: {
                    returnTo: `${process.env.AUTH0_ORIGIN}/on_logout`,
                },
                openUrl,
            });
        });
    }
    //
    // Gets authendicated configuration for requests.
    //
    function getRequestConfig() {
        return __awaiter(this, void 0, void 0, function* () {
            const token = yield getAccessTokenSilently();
            return {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            };
        });
    }
    const value = {
        isAuthEnabled: true,
        isLoading: isLoading,
        isAuthenticated: isAuthenticated,
        error,
        login,
        logout,
        getRequestConfig,
    };
    return (react_1.default.createElement(user_interface_1.AuthContext.Provider, { value: value }, children));
}
//
// Check an environment variable.
//
function checkEnvironmentVariable(name, value) {
    if (!value) {
        throw new Error(`Environment variable ${name} is not set.`);
    }
}
//
// Make sure auth0 settings are enabled.
//
function validateAuthSettings() {
    checkEnvironmentVariable("AUTH0_DOMAIN", process.env.AUTH0_DOMAIN);
    checkEnvironmentVariable("AUTH0_CLIENT_ID", process.env.AUTH0_CLIENT_ID);
    checkEnvironmentVariable("AUTH0_AUDIENCE", process.env.AUTH0_AUDIENCE);
    checkEnvironmentVariable("AUTH0_ORIGIN", process.env.AUTH0_ORIGIN);
}
